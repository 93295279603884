import React from 'react';

import Profile from './components/Profile';
import Tech from './components/Tech';
import Certifications from './components/Certifications';
import MyProjects from './components/MyProjects';
import About from './components/About';
import Footer from './components/Footer';

import profile from './img/profile3.png';

function App() {
  return (
    <>
      <Profile pic={profile}/>
      <About />
      <Tech />
      <Certifications />
      <MyProjects />
      <Footer />
    </>
  );
}

export default App;
