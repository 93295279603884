import React, { useEffect } from 'react';

function Certifications() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//cdn.credly.com/assets/utilities/embed.js';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once after mounting

  return (
    <div className="py-4">
      <div className='mb-12 text-4xl font-bold font-txt text-center'>Certifications</div>
      <div className='mb-16 flex flex-wrap justify-center items-center gap-x-20 gap-y-12'>
        <div data-iframe-width="190" data-iframe-height="270" data-share-badge-id="420a632f-0985-4008-a121-98b5f2b413e6" data-share-badge-host="https://www.credly.com"></div>
        <div data-iframe-width="190" data-iframe-height="270" data-share-badge-id="d766683a-10f1-4fa6-967f-c5b44777b700" data-share-badge-host="https://www.credly.com"></div>
      </div>
    </div>
  );
}

export default Certifications;
