import React, { useEffect } from 'react';

function About() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://substackapi.com/embeds/feed.js';
    script.async = true;
    document.body.appendChild(script);

    window.SubstackFeedWidget = {
      substackUrl: 'sahasramesh.substack.com',
      posts: 3,
      hidden: ['image']
    };
  }, []);

  return (
    <>
      <div className='flex md:flex-row flex-col justify-center items-center mt-8'>
        <div className='font-txt text-lg leading-loose md:w-1/2 w-4/5 m-8'>
          <div className='text-4xl font-bold font-txt leading-loose'>Hi 👋</div>
          I'm currently a researcher at the
          {' '}
          <a href='https://www.ncsa.illinois.edu/' target='_blank' rel='noreferrer'>
            National Center for Supercomputing Applications
          </a>. 
          I love working with applied cognitive science, web development, and social and 
          computer networking. I've conducted research on infant mental health through 
          maternal interactions and wearable device data,
          {' '}
          <a href='https://publish.illinois.edu/socialdevlab/2023/08/02/ncsa-spin-presentation/' target='_blank' rel='noreferrer'>
            presenting my work at a symposium
          </a>. 
          I play the Indian classical violin, which has give me the opportunity to learn, 
          teach, and perform all over the world. In my spare time, I like to read and write, 
          check out some of my latest work here
          <span className='md:hidden'>  &#x2193;</span>
          <span className='md:inline hidden'>  &#x2192;</span>
        </div>
        <div className='md:w-1/3 w-4/5 border rounded-lg m-8'>
          <div id="substack-feed-embed"></div>
        </div>
      </div>
    </>
  );
}

export default About;